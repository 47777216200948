<template>
  <div class="list">
    <div class="header">
      <div class="search">
        <input class="searchInput" v-model="search" />
        <span class="material-icons searchIcon"> search </span>
      </div>
      <h2 class="header">{{ f.toUpperCase() }}</h2>
      <router-link :to="{ name: 'AddPost' }">
        <div class="add">+</div>
      </router-link>
    </div>
    <div v-for="post in matchingPosts" :key="post.id">
      <list-item :post="post" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  components: {
    "list-item": require("./ListItem.vue").default,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    f: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const search = ref("");
    const filtered = computed(() => {
      if (props.f) {
        return props.posts.filter((p) => p.region === props.f).slice(0, 10);
      }
      if (props.tag) {
        return props.posts
          .filter((p) => p.tags.includes(props.tag))
          .slice(0, 10);
      }
      return props.posts;
    });
    const matchingPosts = computed(() =>
      filtered.value.filter((filteredPost) =>
        filteredPost.title.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    return { filtered, search, matchingPosts };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #39a5eb;
$backcolor: #f7f7f7;
$listItemHeight: 120px;

.list {
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0px 3px 25px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  font-family: "Exo", sans-serif;
  font-weight: 700;
  background-color: #ccc;
  color: white;
  margin: 0 0 3px 0;
  border-radius: 5px;
  overflow: hidden;

  h2 {
    display: block;
    flex-basis: 100%;
  }
  a {
    text-decoration: none;
  }
}
.add {
  display: flex;
  height: 100%;
  width: 30px;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: white;
  cursor: pointer;
}
.add:hover {
  background-color: $secondcolor;
}
.search {
  position: relative;
  align-items: center;
}
.searchInput {
  padding-left: 30px;
  height: 100%;
  border: 0;
  width: 120px;
  background: white;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #a1a1a1;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.searchIcon {
  position: absolute;
  color: #ddd;
  line-height: 38px;
  left: 5px;
}
</style>
