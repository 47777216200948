import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'

const useDocument = (collection, id) => {
    // console.log(`kolekcja: ${collection}`)
    // console.log(`id: ${id}`)
    const error = ref(null)
    const isPending = ref(false)

    let docRef = projectFirestore.collection(collection).doc(id)

    const deleteDoc = async () => {
        error.value = null
        isPending.value = true
        try {
            const response = await docRef.delete();
            isPending.value = false
            console.log(`usunięto w bazie wpis: ${id}`)
            return response
        } catch (err) {
            error.value = err.message
            isPending.value = false
            console.log(error)
        }
    }

    const editDoc = async (updates) => {
        error.value = null
        isPending.value = true
        // console.log(`zmiana: ${updates}`)
        try {
            const response = await docRef.update(updates);
            isPending.value = false
            console.log(`zmieniono w bazie wpis: ${id}`)
            return response
        } catch (error) {
            error.value = error.message
            isPending.value = false
            console.log(error)
        }
    }
    return { error, isPending, deleteDoc, editDoc }
}

export default useDocument