<template>
  <div v-if="tags.length" class="mainWraper">
    <div>tagi:</div>
    <transition-group name="tagi">
      <div v-for="tag in tags" class="tags" :key="tag">
        <router-link
          v-if="link"
          class="oneTag"
          :class="{ allowHoverLink: allowHover }"
          :to="{ name: 'PostsByTag', params: { tag: tag } }"
          >#{{ tag }}</router-link
        >
        <span
          v-if="!link"
          class="oneTag"
          :class="{ allowHoverDelete: allowHover }"
          @click="removeTag(tag)"
          >#{{ tag }}</span
        >
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
    },
    link: Boolean,
    allowHover: Boolean,
  },

  setup(props, { emit }) {
    const removeTag = (tag) => emit("deletetag", tag);
    return { removeTag };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.mainWraper {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tags {
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.oneTag {
  margin: 3px 5px;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  color: #555;
  cursor: pointer;
}
.allowHoverDelete:hover {
  background-color: red;
  color: white;
  cursor: pointer;
}
.allowHoverLink:hover {
  @extend .allowHoverDelete:hover;

  background-color: $secondcolor;
}
.tagi-enter-from,
.tagi-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.tagi-enter-active,
.tagi-leave-active {
  transition: all 1s ease;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
  }
}
</style>
