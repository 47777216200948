<template>
  <transition-group tag="div" name="view" appear>
    <div v-if="onePost" class="wraper">
      <div class="mainView">
        <div v-if="onePost.imageUrl.length" class="authorPhoto">
          <img :src="onePost.imageUrl" />
        </div>
        <div id="icons">
          <div></div>
          <div class="icons">
            <router-link
              v-if="ownership"
              :to="{ name: 'EditPost', params: { id: onePost.id } }"
            >
              <span class="material-icons dark"> edit </span>
            </router-link>
            <span
              v-if="ownership"
              @click="showCloseModal = true"
              class="material-icons dark"
            >
              delete
            </span>
          </div>
        </div>
        <div class="description">
          <h1 @click="forceUpdateComponent">{{ onePost.title }}</h1>
          <h3>{{ onePost.subtitle }}</h3>
          <div class="tags">
            <tags-line allowHover link :tags="onePost.tags" />
          </div>
          <div class="stopka">
            <h5>{{ onePost.createdBy }}</h5>
            <router-link
              :to="{
                name: 'PostsByUser',
                params: {
                  user: onePost.createdBy,
                  uid: onePost.userId,
                  name: onePost.createdBy,
                },
              }"
            >
              <img
                :src="authorPhoto ? authorPhoto : require('@/assets/zew.png')"
              />
            </router-link>
            <h5>{{ onePost.creationDate }}</h5>
          </div>
          <p v-html="onePost.body"></p>
          <div v-if="onePost.video">
            <iframe :src="onePost.video" frameborder="0"></iframe>
          </div>
        </div>
      </div>
      <div class="sidebar">
        <side-list :f="onePost.region" :posts="filteredByIdPosts" />
      </div>
    </div>
    <modal-delete
      v-if="showCloseModal"
      :postId="onePost.id"
      @deletePost="handleDeletePost"
      @close="showCloseModal = false"
      :text1="$store.state.deleteModalText1"
      :text2="$store.state.deleteModalText2"
    />
  </transition-group>
</template>

<script>
import getOnePost from "@/composables/getOnePost_firebase";
import { computed, ref, onMounted, onUpdated } from "vue";
import { useRoute, useRouter } from "vue-router";
import { projectFirestore } from "@/firebase/config";
import { useStore } from "vuex";
import useOwnership from "@/composables/useOwnership";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";

export default {
  components: {
    "side-list": require("@/components/List/List.vue").default,
    "tags-line": require("@/components/TagsLine.vue").default,
    "modal-delete": require("@/components/Modal.vue").default,
  },
  props: {
    id: {
      type: String,
    },
    uid: String,
  },

  setup(props) {
    onMounted(() => {
      store.dispatch("getPosts");
    });

    const { onePost, errOnePost, loadPost } = getOnePost(props.id);
    const { deleteDoc, error } = useDocument("posts", props.id);
    const { deleteImage, error: imageError } = useStorage();
    const store = useStore();
    const posts = computed(() => store.state.posts);
    const route = useRoute();
    const router = useRouter();
    const modalVis = ref(false);
    const authorPhoto = ref(null);

    const { ownership } = useOwnership(props.uid);
    const showCloseModal = ref(false);

    loadPost();

    const filteredByRegionPosts = computed(() =>
      posts.value.filter((post) => post.region === onePost.value.region)
    );

    const filteredByIdPosts = computed(() =>
      filteredByRegionPosts.value.filter((post) => post.id !== onePost.value.id)
    );

    const getAuthorPhoto = async () => {
      const authors = ref(null);
      const response = await projectFirestore.collection("authors").get();
      authors.value = response.docs.map((doc) => ({
        ...doc.data(),
      }));
      authors.value.forEach((author) => {
        if (author.name.includes(onePost.value.createdBy)) {
          authorPhoto.value = author.imageUrl;
        }
      });
    };

    getAuthorPhoto();

    const handleDeletePost = async () => {
      if (ownership) {
        await deleteImage(onePost.value.filePath);
        await deleteDoc();
        router.push({ name: "Posts" });
      } else {
        alert("Nie masz uprawnien do usunięcia posta.");
        showDeleteModal.value = false;
      }
    };

    return {
      onePost,
      errOnePost,
      posts,
      filteredByIdPosts,
      route,
      router,
      modalVis,
      getAuthorPhoto,
      authorPhoto,
      showCloseModal,
      ownership,
      handleDeletePost,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$marginHorizontal: 20px;

.wraper {
  display: flex;
}
.mainView {
  padding: 0 $marginHorizontal;
  flex-shrink: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: right;
  width: 100%;
  h1 {
    color: $secondcolor;
    padding-right: 50px;
  }
}
h1 {
  border-bottom: 1px solid $maincolor;
  font-family: "Exo", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: -35px;
  text-align: left;
  display: block;
  width: 100%;
  // background-color: $backcolor;
}
h2,
h3 {
  text-align: left;
  font-weight: 900;
}
p {
  // margin: 20px 0;
  text-align: left;
}
.sidebar {
  width: 500px;
  padding: 0 $marginHorizontal 0 0;
}
.authorPhoto {
  flex-shrink: 0;
  background-color: $backcolor;
  width: 100%;
  display: flex;

  img {
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
  }
}
.listItem {
  background-color: white;
}
.tags {
  border-top: 1px solid $maincolor;
  margin-top: 15px;
  padding-top: 20px;
}
.icons {
  width: 50px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 50px;
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  z-index: 100;
}
.material-icons:hover {
  color: $secondcolor;
  cursor: pointer;
}
.dark {
  color: #555;
}
#icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.stopka {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  a {
    display: flex;
    align-items: center;
  }
  a img {
    height: 55px;
    width: 55px;
    object-fit: cover;
    border-radius: 10px;
  }
}
iframe {
  width: 100%;
  height: 500px;
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  h1 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
}
.view-enter-from,
.view-leave-to {
  opacity: 0;
  transform: translateX(1200px);
}
.view-enter-active,
.view-leave-active {
  transition: all 0.5s ease-in;
}
</style>
