<template>
  <h2>No such Page</h2>
  <h1>404</h1>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
h1 {
  color: $secondcolor;
  margin-top: 200px;
}
</style>
