<template>
  <div class="wraper">
    <p class="error">{{ submitError }}</p>
    <form @submit.prevent="handleSubmitForm" class="addNewsForm">
      <p>Region:</p>
      <RegionSelect required :option="region" @setRegion="region = $event" />
      <transition name="tagi">
        <TagsLine allowHover @deletetag="handleRemoveTag" :tags="tags" />
      </transition>
      <input
        class="input title"
        type="text"
        placeholder="tytuł"
        v-model="title"
        required
      />
      <input
        class="input subtitle"
        type="text"
        placeholder="podtytuł"
        v-model="subtitle"
      />
      <TinyEditor @text="body = $event" />
      <!-- <TinyEditor textIn="" @text="setBody" /> -->
      <div class="photoInput">
        <label>Dodaj zdjęcie: </label>
        <input type="file" @change="handleAddPhotoFile" />
        <p class="error">{{ photoError }}</p>
      </div>
      <div class="optionalInputs">
        <input type="checkbox" @change="showVideo = !showVideo" />
        <label>Dodaj link wideo</label>
        <input type="checkbox" @change="showPhoto2 = !showPhoto2" />
        <label>Dodaj foto 2</label>
        <input type="checkbox" @change="showPhoto3 = !showPhoto3" />
        <label>Dodaj foto 3</label>
        <input type="checkbox" @change="showPhoto4 = !showPhoto4" />
        <label>Dodaj foto 4</label>
      </div>
      <input
        v-if="showVideo"
        class="input link"
        type="text"
        placeholder="link wideo (YT, Vimeo, etc)"
        v-model="video"
      />
      <!-- na razie nie działa dodawanie kolejnych zdjęć ?????-->
      <div v-if="showPhoto2" class="photoInput">
        <label>Dodaj zdjęcie: </label>
        <input type="file" @change="handleAddPhotoFile" />
        <p class="error">{{ photoError }}</p>
      </div>
      <div v-if="showPhoto3" class="photoInput">
        <label>Dodaj zdjęcie: </label>
        <input type="file" @change="handleAddPhotoFile" />
        <p class="error">{{ photoError }}</p>
      </div>
      <div v-if="showPhoto4" class="photoInput">
        <label>Dodaj zdjęcie: </label>
        <input type="file" @change="handleAddPhotoFile" />
        <p class="error">{{ photoError }}</p>
      </div>
      <!-- na razie nie działa dodawanie kolejnych zdjęć -->
      <input
        @keyup="handleAddTag"
        class="input"
        type="text"
        v-model="tag"
        placeholder="TAGI dodaje się przez wciśnięcie PRZECINKA, a usuwa klikając w TAG"
      />
      <Button
        :disabled="saveIsPending"
        textDisabled="zapisywanie ...."
        text="zapisz"
      />
    </form>
    <p v-if="false">{{ body }}</p>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import { timestamp } from "@/firebase/config";
import Button from "@/components/Button.vue";
import RegionSelect from "@/components/RegionSelect.vue";
import TagsLine from "@/components/TagsLine.vue";
import TinyEditor from "@/components/TinyEditor.vue";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
import addPhoto from "@/composables/addPhoto";

export default {
  components: {
    Button,
    RegionSelect,
    TagsLine,
    TinyEditor,
  },
  setup() {
    const { url, filePath, uploadPhoto } = useStorage();
    const { error, addDoc } = useCollection("posts");
    const { user } = getUser();
    const { handleAddPhotoFile, photoError, image } = addPhoto();

    const showVideo = ref(false);
    const showPhoto2 = ref(false);
    const showPhoto3 = ref(false);
    const showPhoto4 = ref(false);

    const saveIsPending = ref(false);

    const tagSet = new Set();
    const tag = ref("");
    const router = useRouter();

    const submitError = ref(null);

    const region = ref("");
    const title = ref("");
    const subtitle = ref("");
    const body = ref("");
    const tags = ref([]);
    const creationDate = new Date().toLocaleDateString();
    const photo = image;
    const video = ref("");

    // const setBody = (text) => (body.value = text);

    const handleAddTag = (e) => {
      if (e.key === "," && tag.value) {
        if (tag.value !== ",") {
          const reg = /,/g;
          tagSet.add(tag.value.toLowerCase().replace(reg, "").trim());
          tags.value = [...tagSet].filter((value) => value !== "");
          tag.value = "";
        }
      }
    };

    const handleRemoveTag = (e) => {
      tagSet.delete(e);
      tags.value = [...tagSet];
      // tags.value = tags.value.filter((tag) => tag !== event);
    };

    const handleSubmitForm = async () => {
      if (photo.value) {
        submitError.value = null;
        saveIsPending.value = true;
        await uploadPhoto(photo.value, creationDate);
        console.log("public URL of photo: " + url.value);
        const res = await addDoc({
          region: region.value,
          title: title.value,
          subtitle: subtitle.value,
          body: body.value,
          tags: tags.value,
          created: timestamp(),
          creationDate,
          imageUrl: url.value,
          video: video.value,
          filePath: filePath.value,
          createdBy: user.value.displayName,
          userId: user.value.uid,
        });
        router.push({ name: "List" });
        // router.push({ name: "Posts" });
        // router.push({ name: "PostDetails", params: { id: res.id } });
        saveIsPending.value = false;
        if (!error.value) {
          console.log("dodano dokument do bazy");
          console.log(error.message);
        }
      } else {
        submitError.value = "Nie wybrano pliku zdjęcia! Wybierz jakieś!";
      }
    };

    return {
      region,
      title,
      subtitle,
      body,
      tag,
      tags,
      photo,
      showPhoto2,
      showPhoto3,
      showPhoto4,
      video,
      handleSubmitForm,
      handleAddTag,
      handleRemoveTag,
      handleAddPhotoFile,
      photoError,
      submitError,
      showVideo,
      saveIsPending,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

input:disabled {
  color: red;
}
img {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
}
.wraper {
  font-family: $font;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100vw;
  min-width: 300px;
  h1 {
    color: $secondcolor;
  }
}
.addNewsForm {
  font-family: $font;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  border: 1px solid #ddd;
}
.input {
  font-family: $font;
  font-size: 14px;
  max-width: 100%;
  min-width: 100%;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 15px 0;
  padding: 0 5px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  color: $secondcolor;
}
.subtitle {
  font-weight: 700;
  font-size: 16px;
  color: #555;
}
.link {
  color: $maincolor;
}
.textarea {
  @extend .input;
  min-height: 80px;
}
.select {
  width: 50%;
  font-family: $font;
  font-size: 14px;
  margin-bottom: 10px;
}
.photoInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 25px;
  background-color: $backcolor;
}
.optionalInputs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  background-color: #eee;
  label {
    // margin-left: 10px;
  }
  input[type="checkbox"] {
    margin-left: 20px;
  }
}
.tagi-enter-from,
.tagi-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.tagi-enter-active,
.tagi-leave-active {
  transition: all 1s ease;
}
</style>
