import { createRouter, createWebHistory } from "vue-router";
import List from "@/views/List.vue";
import Chat from "@/views/Chat.vue";
import LogIn from "@/views/LogIn.vue";
import Posts from "@/views/Posts.vue";
import PostsByTag from "@/views/PostsByTag.vue";
import PostsByUser from "@/views/PostsByUser.vue";
import AddPost from "@/views/AddPost.vue";
import EditPost from "@/views/EditPost.vue";
import PostDetails from "@/views/PostDetails.vue";

import Page404 from "../views/Page404.vue";

// route guards
import { projectAuth } from "@/firebase/config"

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: "LogIn" })
  } else {
    next()
  }
}
const ifUserisLoggedIn = (to, from, next) => {
  let user = projectAuth.currentUser
  if (user) {
    next({ name: "Posts" })
  } else {
    next()
  }
}

const routes = [
  {
    path: "/",
    name: "LogIn",
    component: LogIn,
    beforeEnter: ifUserisLoggedIn
  },
  {
    path: "/list",
    name: "List",
    component: List,
    beforeEnter: requireAuth
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    beforeEnter: requireAuth
  },
  {
    path: "/posts",
    name: "Posts",
    component: Posts,
    beforeEnter: requireAuth
  },
  {
    path: "/postsbytag/:tag",
    name: "PostsByTag",
    component: PostsByTag,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: "/postsbyuser/:user",
    name: "PostsByUser",
    component: PostsByUser,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: "/posts/add",
    name: "AddPost",
    component: AddPost,
    beforeEnter: requireAuth
  },
  {
    path: "/post/:id",
    name: "PostDetails",
    component: PostDetails,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: "/post/edit/:id",
    name: "EditPost",
    component: EditPost,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: "/:catchAll(.*)",
    name: "Page404",
    component: Page404,
  },
  {
    path: "/homee",
    redirect: "/",
  },
  // {
  //   path: "/",
  //   component: Home,
  //   meta: {
  //     reload: true,
  //   },
  // },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
