<template>
  <div class="loginWrapper signin">
    <div v-if="login" class="form">
      <h3>logowanie</h3>
      <login-form />
      <h5>
        Jeśli nie masz konta, zarejestruj się
        <span @click="login = false">TU</span>
      </h5>
    </div>
    <div v-else class="form">
      <h3>rejestracja</h3>
      <sign-up-form />
      <h5>
        Jeśli masz konto, zaloguj się
        <span @click="login = true">TU</span>
      </h5>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  components: {
    "login-form": require("@/components/Loginforms/LoginForm").default,
    "sign-up-form": require("@/components/Loginforms/SignUpForm").default,
  },
  setup() {
    const login = ref(true);
    return { login };
  },
};
</script>

<style lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.loginWrapper {
  margin-top: 150px;
}
.form {
  grid-column: 1;
  margin: 50px auto;
  width: 350px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
}
.loginForm {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.loginInput {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}
.signin span {
  cursor: pointer;
  background-color: lightslategray;
  padding: 0 10px;
  color: white;
  border-radius: 10px;
}
.signin span:hover {
  cursor: pointer;
  background-color: red;
}
.error {
  color: red;
}
h5 {
  font-size: 14px;
}
</style>