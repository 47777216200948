<template>
  <div class="wraper">
    <div class="chatWindow">
      <chat-list />
      <chat-form />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "chat-list": require("@/components/ChatElements/ChatList").default,
    "chat-form": require("@/components/ChatElements/ChatForm").default,
  },
  setup() {},
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.wraper {
  margin: 0;
  padding: 0;
  background-color: $backcolor;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatWindow {
  width: 500px;
  height: 800px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
</style>