<template>
  <form class="loginForm" @submit.prevent="handleSubmit">
    <input
      class="loginInput"
      type="email"
      required
      placeholder="e-mail"
      v-model="email"
    />
    <input
      class="loginInput"
      type="password"
      required
      placeholder="hasło"
      v-model="password"
    />
    <p v-if="error" class="error">{{ error }}</p>
    <login-button
      :disabled="
        isPending || email === '' || !email.includes('@') || password.length < 6
      "
      textDisabled="zaloguj"
      text="zaloguj"
    />
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useLogin from "@/composables/useLogin";

export default {
  components: {
    "login-button": require("@/components/Button.vue").default,
  },
  setup() {
    const { login, error, isPending } = useLogin();
    const router = useRouter();

    const email = ref("");
    const password = ref("");

    const handleSubmit = async () => {
      const res = await login(email.value, password.value);
      if (!error.value) {
        console.log(`Zalogowano ${email.value}`);
        router.push({ name: "Posts" });
      }
      return res;
    };

    return { email, password, handleSubmit, error, isPending };
  },
};
</script>