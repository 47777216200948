<template>
  <form class="loginForm" @submit.prevent="handleSubmit">
    <input
      class="loginInput"
      type="name"
      required
      placeholder="imię/nazwa"
      v-model="name"
    />
    <input
      class="loginInput"
      type="email"
      required
      placeholder="e-mail"
      v-model="email"
    />
    <p v-if="password.length > 0 && password.length < 6" class="error">
      Hasło musi mieć minimum 6 znaków
    </p>
    <input
      class="loginInput"
      type="password"
      required
      placeholder="hasło"
      v-model="password"
    />
    <input
      v-if="password"
      class="loginInput"
      type="password"
      required
      placeholder="zweryfikuj hasło"
      v-model="passwordVerify"
    />
    <p v-if="signupError" class="error">{{ signupError }}</p>
    <div v-if="!buttonDisabled" class="optionalInputs">
      <input type="checkbox" @change="showPhoto = !showPhoto" />
      <label>Dodaj swoje foto: </label>
    </div>
    <div v-if="showPhoto" class="photoInput">
      <input type="file" @change="handleAddPhotoFile" />
      <p class="error">{{ photoError }}</p>
    </div>
    <login-button
      :disabled="buttonDisabled"
      :text="buttonText"
      :textDisabled="buttonText"
    />
    <p v-if="sendingData">trwa wysyłanie danych</p>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { watchEffect } from "vue";
import useSignup from "@/composables/useSignup";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import addPhoto from "@/composables/addPhoto";

export default {
  components: {
    "login-button": require("@/components/Button.vue").default,
  },
  setup() {
    const { signup, error: signupError, isPending } = useSignup();
    const { handleAddPhotoFile, photoError, image } = addPhoto();
    const { authorsPhotoError, url, filePath, uploadAuthorsPhoto } =
      useStorage();
    const router = useRouter();

    const name = ref("");
    const email = ref("");
    const password = ref("");
    const passwordVerify = ref("");
    const photo = image;

    const buttonDisabled = ref(true);
    const sendingData = ref(false);
    const buttonText = ref("zarejestruj");
    const showPhoto = ref(false);

    const { error, addDoc } = useCollection("authors");

    // password verification
    watchEffect(() => {
      if (
        email.value !== "" &&
        email.value.includes("@") &&
        password.value.length >= 6 &&
        password.value !== "" &&
        passwordVerify.value.length === password.value.length &&
        passwordVerify.value.includes(password.value)
      ) {
        buttonDisabled.value = false;
      } else buttonDisabled.value = true;
    }, passwordVerify.value);

    // submit form
    const handleSubmit = async () => {
      buttonDisabled.value = true;
      sendingData.value = true;
      const res = await signup(email.value, password.value, name.value);
      if (photo.value) {
        await uploadAuthorsPhoto(photo.value, name);
        console.log("publiczny URL autora: " + url.value);
        console.log("ściezka dostępu Firebase: " + filePath.value);
      }
      if (!signupError.value) {
        console.log(`Załozono nowego uzytkownika o nazwie: ${name.value}`);
        router.push({ name: "Posts" });
        await addDoc({
          name: name.value,
          imageUrl: url.value,
          filePath: filePath.value,
        });
      }
      buttonDisabled.value = false;
      sendingData.value = true;
      return res;
    };

    return {
      name,
      email,
      password,
      passwordVerify,
      handleSubmit,
      signupError,
      isPending,
      buttonDisabled,
      photo,
      showPhoto,
      handleAddPhotoFile,
      photoError,
      authorsPhotoError,
      error,
      buttonText,
      sendingData,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;
.photoInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 25px;
  background-color: $backcolor;
}
.optionalInputs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  label {
    font-size: 14px;
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }
}
</style>