export const regions = {
  denmark: "Dania",
  estonia: "Estonia",
  finland: "Finlandia",
  greenland: "Grenlandia",
  iceland: "Islandia",
  norway: "Norwegia",
  sweden: "Szwecja",
  faroe: "Wyspy Owcze",
  poland: "Polska",
  scandinavia: "Skandynawia",
};
export const regionsAr = Object.values(regions);
export const keys = Object.keys(regions);
export const regionsObj = [
  {
    name: "Dania",
  },
  {
    name: "Estonia",
  },
  {
    name: "Finlandia",
  },
  {
    name: "Grenlandia",
  },
  {
    name: "Islandia",
  },
  {
    name: "Norwegia",
  },
  {
    name: "Szwecja",
  },
  {
    name: "W. Owcze",
  },
];
