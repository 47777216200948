import { createStore } from "vuex";
import { computed } from "vue"
import getItems from "@/composables/getPosts_firebase";
import getCollection from "@/composables/getCollection";

export default createStore({
  state: {
    posts: [],
    deleteModalText1: "Czy napewno chcesz usunąć ten post?",
    deleteModalText2: "Ta operacja jest nie odwracalna!",
  },

  mutations: {
    setPosts(state, posts) {
      state.posts = posts
    },

    delete(state, id) {
      console.log('delete from store: ' + id)
      state.posts = state.posts.filter(post => post.id !== id)
    },
  },

  actions: {
    getPosts({ state, commit }) {
      const { posts, loadData } = getItems();
      loadData()
      commit('setPosts', posts)
    },
  },

  getters: {
    getAllTags(state) {
      const allTags = computed(() => {
        const tagSet = new Set();
        state.posts.forEach((post) =>
          post.tags.forEach((tag) => tagSet.add(tag))
        );
        const uniqueTags = [...tagSet].sort();
        return uniqueTags;
      });
      return allTags;
    }
  }
});