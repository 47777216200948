<template>
  <div class="editor">
    <Editor
      v-model="text"
      api-key="ipifraqhpjqmo5jit6awa2hfnq6fhnvbrrkisjqr6xr220x0"
      :init="{
        min_height: 350,
        menubar: true,
        plugins: 'lists link image emoticons',
        toolbar:
          'styleselect | bold italic  | link emoticons | bullist numlist',
      }"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { ref } from "@vue/reactivity";
import { watchEffect } from "vue";

export default {
  components: { Editor },
  props: ["textIn"],

  setup(props, { emit }) {
    const text = ref(props.textIn);

    watchEffect(() => {
      emit("text", text.value), text.value;
    });

    return { text };
  },
};
</script>

<style>
.editor {
  min-width: 100%;
  margin-bottom: 25px;
}
</style>
