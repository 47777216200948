<template>
  <div>{{ getError }}</div>
  <div class="chatList" ref="messageRef">
    <div
      class="list-item"
      v-for="message in properDatedMessages"
      :key="message.created"
    >
      <div v-if="currentUserName == message.createdBy" class="message-wrapper">
        <div class="message">
          <div class="message-author">
            <img
              :src="
                message.photoBy
                  ? message.photoBy
                  : require(`@/assets/${zaslepka}`)
              "
            />
          </div>
          <div class="message-date">
            <span class="message-me-date">
              <strong>{{ message.createdBy }}</strong>
            </span>
            <span class="message-me-date">
              {{ message.createdAt }}
            </span>
          </div>
        </div>
        <div class="message-me-text">
          {{ message.message }}
        </div>
      </div>
      <div v-else class="message-wrapper-notme">
        <div class="message-notme">
          <div class="message-date">
            <span class="message-notme-date">
              <strong>{{ message.createdBy }}</strong>
            </span>
            <span class="message-notme-date">
              {{ message.createdAt }}
            </span>
          </div>
          <div class="message-author">
            <img
              :src="
                message.photoBy
                  ? message.photoBy
                  : require(`@/assets/${zaslepka}`)
              "
            />
          </div>
        </div>
        <div class="message-notme-text">
          {{ message.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onUpdated } from "vue";
import getUser from "@/composables/getUser";

import getCollectionRealTime from "@/composables/getCollectionRealTime";
import { formatDistanceToNow, format } from "date-fns";

export default {
  setup() {
    const { user } = getUser();
    const { error: getError, documents: messages } =
      getCollectionRealTime("chat");

    const currentUserName = user.value.displayName;

    const properDatedMessages = computed(() => {
      if (messages.value) {
        return messages.value.map((mess) => {
          // let properDate = formatDistanceToNow(mess.created.toDate());
          let properDate = format(
            mess.created.toDate(),
            "d MMMM yyyy  –  HH:mm:ss"
          );
          return { ...mess, createdAt: properDate };
        });
      }
    });

    // getError = "AAAAAAAA";

    const messageRef = ref(null);
    onUpdated(() => {
      messageRef.value.scrollTop = messageRef.value.scrollHeight;
    });

    const zaslepka = "zew.png";

    return {
      currentUserName,
      properDatedMessages,
      messageRef,
      getError,
      zaslepka,
    };
  },
};
</script>

<style lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.chatList {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding-bottom: 20px;
}
.list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  span {
    margin: 0 5px;
    text-align: left;
  }
  .message {
    display: flex;
    width: 100%;
    height: 40px;
    &-notme {
      display: flex;
      justify-content: flex-end;
    }
    &-wrapper {
      margin: 5px 0;
      &-notme {
        align-self: flex-end;
        margin: 5px 0;
      }
    }
    &-author {
      width: 40px;
      height: 40px;
      img {
        border-radius: 5px;
        width: 100%;
        height: 40px;
        object-fit: cover;
      }
    }
    &-date {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &-me {
      &-date {
        font-size: 10px;
      }
      &-text {
        color: white;
        border-radius: 5px;
        background-color: $secondcolor;
        padding: 0 15px;
        margin-top: 5px;
      }
    }
    &-notme {
      &-date {
        align-self: flex-end;
        font-size: 10px;
      }
      &-text {
        align-self: flex-end;
        color: white;
        border-radius: 5px;
        background-color: $maincolor;
        padding: 0 15px;
        margin-top: 5px;
        text-align: right;
      }
    }
  }
}
</style>