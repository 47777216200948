import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCf-BzUpwzsjbJ-Z0rm6B6ep6B_-uM6y7c",
  authDomain: "northerncall.firebaseapp.com",
  projectId: "northerncall",
  storageBucket: "northerncall.appspot.com",
  messagingSenderId: "527278386083",
  appId: "1:527278386083:web:569314bde20c68a4afc816",
  measurementId: "G-QSD5D5ZZ38",
};

//initialize firebase app
firebase.initializeApp(firebaseConfig);

//initialize firestore services
const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();

//initialize timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore, projectStorage, timestamp };
