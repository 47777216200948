<template>
  <div class="wraper">
    <div class="foto">
      <img
        class="author"
        :src="authorsPhoto ? authorsPhoto : require('@/assets/zew.png')"
      />
      <p>{{ author }}</p>
    </div>
    <list-by-user :posts="posts" />
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";
import useAuthor from "@/composables/useAuthor";
import { ref } from "@vue/reactivity";
export default {
  props: {
    uid: String,
    name: String,
    uuser: String,
  },
  components: {
    "list-by-user": require("@/components/List/List").default,
  },
  setup(props) {
    const { user } = getUser();
    const where = ref("");
    const author = ref("");

    if (!props.uid) {
      where.value = user.value.uid;
      author.value = user.value.displayName;
    } else {
      where.value = props.uid;
      author.value = props.name;
    }
    const {
      loadData,
      error,
      documents: posts,
    } = getCollection("posts", ["userId", "==", where.value]);

    const { authorsPhoto, getAuthorsPhoto } = useAuthor(
      "authors",
      author.value
    );

    loadData();
    getAuthorsPhoto();

    return { posts, error, authorsPhoto, user, author };
  },
};
</script>

<style lang="scss">
.wraper {
  padding: 0 25px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 10px;
  img.author {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
}
.foto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>