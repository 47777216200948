import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const getOnePost = (id) => {
  const onePost = ref(null);
  const errOnePost = ref(null);

  const loadPost = async () => {
    try {
      let response = await projectFirestore.collection("posts").doc(id).get();
      if (!response.exists) {
        throw Error(`barak rekordu`);
      }
      onePost.value = { ...response.data(), id: response.id };
    } catch (e) {
      errOnePost.value = e.message;
      console.log(errOnePost.value);
    }
  };
  return { onePost, errOnePost, loadPost };
};

export default getOnePost;
