<template>
  <div class="chatForm">
    <form>
      <textarea
        v-model="message"
        placeholder="wpisz tekst wiadomości"
        @keypress.enter.prevent="handleAddMessage"
        autofocus
      ></textarea>
    </form>
    <transition name="snack-error">
      <error-message
        v-if="snackError"
        :mess="addError"
        @click="snackError = false"
      />
    </transition>
    <transition name="snack-sent">
      <error-message
        v-if="snackSent"
        mess="Wiadomość wysłana"
        sent
        @click="snackSent = false"
      />
    </transition>
    <transition name="snack-nodata">
      <error-message
        v-if="snackNodata"
        mess="Wpisz coś!"
        nodata
        @click="snackNodata = false"
      />
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import useAuthor from "@/composables/useAuthor";
import useCollection from "@/composables/useCollection";

export default {
  components: {
    "error-message": require("@/components/SnackBar").default,
  },
  setup() {
    const { user } = getUser();
    const { error: addError, addDoc } = useCollection("chat");
    const message = ref("");
    const snackError = ref(false);
    const snackSent = ref(false);
    const snackNodata = ref(false);

    const messSentToggle = () => (snackSent.value = !snackSent.value);
    const snackErrorToggle = () => (snackError.value = !snackError.value);
    const snackNodataToggle = () => (snackNodata.value = !snackNodata.value);

    const hideSnackbar = () =>
      setTimeout(() => {
        snackError.value = false;
        snackSent.value = false;
        snackNodata.value = false;
      }, 2000);

    const author = ref("");
    author.value = user.value.displayName;

    const { authorsPhoto, getAuthorsPhoto } = useAuthor(
      "authors",
      author.value
    );

    getAuthorsPhoto();

    const handleAddMessage = async () => {
      const mess = {
        created: timestamp(),
        createdBy: user.value.displayName,
        photoBy: authorsPhoto.value,
        message: message.value,
      };
      if (message.value) {
        await addDoc(mess);
        message.value = "";
        if (!addError.value) {
          messSentToggle();
        } else {
          snackErrorToggle();
        }
      } else {
        snackNodataToggle();
      }
      hideSnackbar();
    };

    return {
      message,
      handleAddMessage,
      addError,
      snackError,
      snackSent,
      snackNodata,
      messSentToggle,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.chatForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // border-top: 2px solid $maincolor;
  textarea {
    min-height: 100px;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    border: none;
    font-size: 16px;
    background-color: $backcolor;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    margin: 20px 0 -0 0;
  }
}

.snack-sent-enter-from,
.snack-sent-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(-150px);
}
.snack-error-enter-from,
.snack-error-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(100%);
}
.snack-nodata-enter-from,
.snack-nodata-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateX(150%);
}
.snack-sent-enter-active,
.snack-sent-leave-active,
.snack-error-enter-active,
.snack-error-leave-active,
.snack-nodata-enter-active,
.snack-nodata-leave-active {
  transition: all 0.7s ease;
}
</style>