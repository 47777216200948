<template>
  <div class="wraper">
    <h3 v-if="errOnePost">{{ errOnePost }}</h3>
    <form v-if="onePost" @submit.prevent="handleSubmitForm" class="addNewsForm">
      <img :src="onePost.imageUrl" :alt="onePost.imageUrl" />
      <RegionSelect
        :option="onePost.region"
        @setRegion="onePost.region = $event"
      />
      <TagsLine allowHover @deletetag="handleRemoveTag" :tags="onePost.tags" />
      <input
        class="input title"
        type="text"
        placeholder="tytuł"
        v-model="onePost.title"
      />
      <input
        class="input"
        type="text"
        placeholder="podtytuł"
        v-model="onePost.subtitle"
      />
      <TinyEditor :textIn="onePost.body" @text="onePost.body = $event" />
      <input
        class="input link"
        type="text"
        placeholder="link wideo (YT, Vimeo, etc)"
        v-model="onePost.video"
      /><input
        @keyup="handleAddTag"
        class="input"
        type="text"
        v-model="tempTag"
        placeholder="TAGI dodaje się przez wciśnięcie PRZECINKA, a usuwa klikając w TAG"
      />
      <Button
        :disabled="saveIsPending"
        textDisabled="zapisywanie ...."
        text="uaktualnij"
      />
    </form>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import getOnePost from "@/composables/getOnePost_firebase";
import useDocument from "@/composables/useDocument";

import Button from "@/components/Button.vue";
import RegionSelect from "@/components/RegionSelect.vue";
import TagsLine from "@/components/TagsLine.vue";
import TinyEditor from "@/components/TinyEditor.vue";

export default {
  components: {
    Button,
    RegionSelect,
    TagsLine,
    TinyEditor,
  },
  props: ["id"],

  setup(props) {
    console.log(`ID postu wybranego do edycji: ${props.id}`);
    const router = useRouter();
    const route = useRoute();
    const { onePost, errOnePost, loadPost } = getOnePost(props.id);
    const { editDoc, error: editDocError } = useDocument(
      "posts",
      route.params.id
    );

    const tempTag = ref("");
    const saveIsPending = ref(false);

    loadPost();

    // mona pomyśleć o zrobieniu z tego kodu COMPSABLE
    const handleAddTag = (e) => {
      const reg = /,/g;
      const value = tempTag.value.toLowerCase().replace(reg, "").trim();
      if (e.key === "," && tempTag.value) {
        if (value !== "") {
          if (!onePost.value.tags.includes(value)) {
            onePost.value.tags.push(value);
          }
        }
        tempTag.value = "";
      }
    };

    const handleRemoveTag = (e) => {
      onePost.value.tags = onePost.value.tags.filter((tag) => tag !== e);
    };
    // ––––––––––––––––––––––––––––––––––––––––––––––

    const handleSubmitForm = async () => {
      const post = {
        region: onePost.value.region,
        title: onePost.value.title,
        subtitle: onePost.value.subtitle,
        body: onePost.value.body,
        video: onePost.value.video,
        tags: onePost.value.tags,
      };
      saveIsPending.value = true;
      await editDoc(post);
      router.push({
        name: "PostDetails",
        params: { id: route.params.id, uid: onePost.value.userId },
      });
    };

    return {
      onePost,
      errOnePost,
      handleSubmitForm,
      tempTag,
      handleAddTag,
      handleRemoveTag,
      saveIsPending,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

input:disabled {
  color: red;
}
img {
  margin-bottom: 40px;
  width: 100%;
  border-radius: 5px;
}
.wraper {
  font-family: $font;

  // background-color: $backcolor;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100vw;
  min-width: 300px;
  h1 {
    color: $secondcolor;
  }
}
.addNewsForm {
  font-family: $font;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  margin-top: 25px;
  border: 1px solid #ddd;
}
.input {
  font-family: $font;
  font-size: 14px;
  max-width: 100%;
  min-width: 100%;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 15px 0;
  padding: 0 5px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  color: $secondcolor;
}
.link {
  color: $maincolor;
}
.textarea {
  @extend .input;
  min-height: 80px;
}
.select {
  width: 50%;
  font-family: $font;
  font-size: 14px;
  margin-bottom: 30px;
}
</style>
