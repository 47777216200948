<template>
  <div id="listItem" class="listItem" :class="{ current: showDescription }">
    <div>
      <router-link
        class="photo"
        :to="{ name: 'PostDetails', params: { id: post.id, uid: post.userId } }"
      >
        <img :src="post.imageUrl" :alt="post.title" />
      </router-link>
    </div>
    <div class="main">
      <div class="title">
        <h3 @click="showDescription = !showDescription">
          {{ post.title }}
        </h3>
      </div>
      <div v-if="showDescription" class="decription">
        <h5>{{ post.region }}</h5>
        <h5>Data utworzenia: {{ post.creationDate }}</h5>
        <p v-html="snippet"></p>
        <!-- <tag-line :tags="post.tags" /> -->
        <h5>Id: {{ post.id }}</h5>
      </div>
    </div>
    <div v-if="showDescription" class="icons">
      <router-link
        :to="{ name: 'PostDetails', params: { id: post.id, uid: post.userId } }"
      >
        <span class="material-icons" :class="{ dark: showDescription }">
          preview
        </span>
      </router-link>
      <router-link :to="{ name: 'EditPost', params: { id: post.id } }">
        <span
          v-if="ownership"
          class="material-icons"
          :class="{ dark: showDescription }"
        >
          edit
        </span>
      </router-link>
      <span
        v-if="ownership"
        @click="showDeleteModal = true"
        class="material-icons"
        :class="{ dark: showDescription }"
      >
        delete
      </span>
      <span class="material-icons" :class="{ dark: showDescription }">
        done
      </span>
    </div>
    <delete-modal
      v-if="showDeleteModal"
      :postId="post.id"
      @deletePost="handleDeletePost"
      @close="showDeleteModal = false"
      :text1="$store.state.deleteModalText1"
      :text2="$store.state.deleteModalText2"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "vue";
import { useStore } from "vuex";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import useOwnership from "@/composables/useOwnership";
import TagsLine from "../TagsLine.vue";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  components: {
    "delete-modal": require("@/components/Modal.vue").default,
    "tag-line": require("@/components/TagsLine.vue").default,
  },

  setup(props) {
    const { deleteDoc, error } = useDocument("posts", props.post.id);
    const { deleteImage, error: imageError } = useStorage();
    const { ownership } = useOwnership(props.post.userId);
    const showDeleteModal = ref(false);
    const showDescription = ref(false);
    const store = useStore();

    const snippet = computed(() => `${props.post.body.slice(0, 100)} ...`);

    const handleDeletePost = async () => {
      if (ownership) {
        await deleteImage(props.post.filePath);
        await deleteDoc();
        store.commit("delete", props.post.id);
      } else {
        alert("Nie masz uprawnien do usunięcia posta.");
        showDeleteModal.value = false;
      }
    };

    return {
      showDescription,
      handleDeletePost,
      snippet,
      showDeleteModal,
      ownership,
    };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$listItemHeight: 120px;

#listItem.current {
  background-color: rgba($secondcolor, 0.15);
}
.listItem {
  align-items: stretch;
  background-color: $backcolor;
  border-radius: 5px;
  display: flex;
  margin: 3px 0;
  min-height: $listItemHeight;
  overflow: hidden;
}
.listItem:hover {
  background-color: rgba($secondcolor, 0.15);
}
h2,
h3,
h4,
h5 {
  font-family: "Exo", sans-serif;
  font-weight: 700;
  margin: 0 5px;
  text-align: left;
  padding: 0 5px;
  // flex-basis: 100%;
  // background-color: $secondcolor;
}
h5 {
  color: #555;
}
h4 {
  color: $maincolor;
}
.listItem h3 {
  color: $secondcolor;
  margin: 3px;
}
p {
  text-align: left;
  padding: 0 5px;
  margin: 0 5px;
  // background-color: $secondcolor;
}
.photo {
  display: flex;
  height: 100%;
  margin-right: 10px;

  img {
    width: $listItemHeight;
    object-fit: cover;
  }
}
.main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0;
}
.title {
  cursor: pointer;
  text-align: left;
  flex-basis: 100%;
  display: flex;

  h3 {
    display: inline-block;
    padding: 0 5px;
    border-radius: 3px;
    // flex-basis: 100%;
  }
  h3:hover {
    background-color: $secondcolor;
    color: white;
  }
}
.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-grow: 5;
  min-width: 50px;
  padding: 0 5px;
  // background-color: $secondcolor;
}
.material-icons {
  color: #c1c1c1;
}
.material-icons:hover {
  color: $secondcolor;
  cursor: pointer;
}
.dark {
  color: #555;
}
</style>