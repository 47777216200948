<template>
  <div class="onError" :class="{ onSent: sent, onNodata: nodata }">
    {{ mess }}
  </div>
</template>

<script>
export default {
  props: {
    mess: String,
    sent: Boolean,
    nodata: Boolean,
  },
};
</script>

<style>
.onError {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 85%;
  max-width: 500px;
  height: 50px;
  bottom: 2%;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  color: white;
  border-radius: 5px;
  background-color: rgb(255, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.onSent {
  background-color: rgba(0, 177, 0, 0.9);
  top: 10%;
}
.onNodata {
  background-color: rgba(255, 123, 0, 0.9);
  top: 50%;
  width: 50%;
  max-width: 250px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */
}
</style>