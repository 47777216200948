import { ref } from 'vue'
import { projectStorage } from '@/firebase/config'

const useStorage = () => {
    const error = ref(null)
    const authorsPhotoError = ref(null)
    const url = ref(null)
    const filePath = ref(null)

    const uploadPhoto = async (file, creationDate) => {
        filePath.value = `/posts/${creationDate}/photos/${file.name}`

        const storageRef = projectStorage.ref(filePath.value)

        try {
            const res = await storageRef.put(file)
            url.value = await res.ref.getDownloadURL()
            console.log(`dodano plik foto: ${filePath.value}`)
            return res
        } catch (err) {
            console.log(err.message)
            error.value = err.message
        }
    }

    const uploadAuthorsPhoto = async (file, name) => {
        filePath.value = `/authors/photos/${name.value}/${file.name}`

        const storageRef = projectStorage.ref(filePath.value)

        try {
            const res = await storageRef.put(file)
            url.value = await res.ref.getDownloadURL()
            console.log(`dodano plik foto autora: ${name.value}`)
            return res
        } catch (err) {
            console.log(err.message)
            error.value = err.message
        }
    }

    const deleteImage = async (patch) => {

        const storageRef = projectStorage.ref(patch)

        try {
            const res = await storageRef.delete()
            console.log(`usunięto plik foto: ${patch}`)
        } catch (err) {
            console.log(err.message)
            error.value = err.message
        }
    }

    return { authorsPhotoError, url, filePath, uploadPhoto, uploadAuthorsPhoto, deleteImage }
}

export default useStorage