import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'

const useAuthor = (collection, name) => {
    const authors = ref(null)
    const authorsPhoto = ref(null)
    // console.log(collection, name)

    const getAuthorsPhoto = async () => {
        const collectionRef = projectFirestore.collection(collection)
        const response = await collectionRef.get();

        authors.value = response.docs.map((doc) => ({
            ...doc.data()
        }));

        // console.log(authors.value)
        authors.value.forEach((author) => {
            if (author.name.includes(name)) {
                authorsPhoto.value = author.imageUrl;
            }
        })
    }
    return { authorsPhoto, getAuthorsPhoto }
}

export default useAuthor;