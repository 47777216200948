<template>
  <div>
    <div class="nav"></div>
    <!-- <div v-if="error">{{ error }}</div> -->
    <div class="news">
      <list-by-region :f="regions.denmark" :posts="posts" />
      <list-by-region :f="regions.estonia" :posts="posts" />
      <list-by-region :f="regions.finland" :posts="posts" />
      <list-by-region :f="regions.greenland" :posts="posts" />
      <list-by-region :f="regions.iceland" :posts="posts" />
      <list-by-region :f="regions.norway" :posts="posts" />
      <list-by-region :f="regions.sweden" :posts="posts" />
      <list-by-region :f="regions.faroe" :posts="posts" />
      <list-by-region :f="regions.scandinavia" :posts="posts" />
      <list-by-region :f="regions.poland" :posts="posts" />
    </div>
    <!-- <div v-else class="viewsWrapper">
      <h2>loading ...</h2>
    </div> -->
  </div>
</template>

<script>
import { onMounted, computed } from "@vue/runtime-core";
import { regions } from "@/composables/regions";
import { useStore } from "vuex";

export default {
  components: {
    "list-by-region": require("@/components/List/List.vue").default,
  },
  setup() {
    const store = useStore();
    const posts = computed(() => store.state.posts);

    onMounted(() => {
      // console.log("getPosts POSTS -> onMounted");
      store.dispatch("getPosts");
    });

    return { regions, posts };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;

.news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  flex-wrap: wrap;
  max-width: 100vw;
  margin: 0 20px;
  height: 100vh;
}
.nav {
  margin: 100px 0 20px 0;
  // background-color: $backcolor;
}

@media (min-width: 2200px) {
  .news {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 1600px) {
  .news {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1200px) {
  .news {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 700px) {
  .news {
    grid-template-columns: 1fr;
  }
}
</style>
