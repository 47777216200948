import { ref, watchEffect } from 'vue'
import { projectFirestore } from '@/firebase/config'

const getCollectionRealTime = (collection) => {

  const documents = ref(null)
  const error = ref(null)

  let collectionRef = projectFirestore.collection(collection)
    .orderBy('created', "asc")

  const unsub = collectionRef.onSnapshot(snap => {
    let results = []
    snap.docs.forEach(doc => {
      doc.data().created && results.push({ ...doc.data(), id: doc.id })
    });

    documents.value = results
    error.value = null
  }, err => {
    console.log(err.message)
    documents.value = null
    error.value = err.message
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, documents }
}

export default getCollectionRealTime