<template>
  <div v-if="allTags.length" class="tagCloud">
    <div class="list" v-for="tag in allTags" :key="tag">
      <router-link
        class="link"
        :to="{ name: 'PostsByTag', params: { tag: tag } }"
      >
        <span>#{{ tag }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const allTags = store.getters.getAllTags;
    return { allTags };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$listItemHeight: 120px;

.tagCloud {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 10;
  // width: 30%;
}
.link {
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  color: #888;
  padding: 0px 8px;
  margin: 3px 2px;
}
.link:hover,
.list a.router-link-active {
  background-color: rgba($secondcolor, 0.4);
  color: #333;
}
</style>
