<template>
  <select class="select" v-model="region">
    <option v-for="option in optionsAr" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script>
import { watchEffect, ref } from "vue";
import { regionsAr } from "../composables/regions";

export default {
  props: ["option"],

  setup(props, { emit }) {
    console.log(`Region otrzymany jako prop: ${props.option}`);
    const region = ref(props.option);
    const optionsAr = regionsAr;

    watchEffect(() => {
      emit("setRegion", region.value);
      console.log(`Wybrałeś region watchEffect: ${region.value}`);
    }, region.value);

    return { region, optionsAr };
  },
};
</script>

<style >
.select {
  margin-bottom: 150px;
}
</style>
