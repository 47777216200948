import { ref } from "vue";
import { projectFirestore } from "@/firebase/config";

const getPosts = () => {
  const posts = ref([]);
  const error = ref(null);

  const loadData = async () => {
    try {
      const response = await projectFirestore
        .collection("posts")
        .orderBy("created", "desc")
        .get();
      if (!response) {
        throw Error(`brak danych`);
      }
      posts.value = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (e) {
      error.value = e.message;
      console.log(error.value);
    }
  };
  return { posts, error, loadData };
};

export default getPosts;
