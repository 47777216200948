import { ref } from "vue"

const addPhoto = () => {
    const photoError = ref(null)
    const image = ref(null)
    const handleAddPhotoFile = (event) => {
        const fileTypes = ["image/jpeg", "image/png"];
        const file = event.target.files[0];

        if (file && fileTypes.includes(file.type)) {
            image.value = file;
            photoError.value = null;
            console.log(image.value)
        } else {
            file.value = null;
            photoError.value = "Proszę wybrać plik JPG lub PNG";
            console.log(photoError.value)
        }
    };
    return { handleAddPhotoFile, photoError, image }
}

export default addPhoto