<template>
  <div
    class="listItem"
    @click="showDetails = !showDetails"
    :class="{ active: showDetails }"
  >
    <span>{{ post.title }}</span>
    <span>{{ post.region }}</span>
    <span>{{ post.creationDate }}</span>
    <router-link
      class="btn"
      :to="{ name: 'PostDetails', params: { id: post.id, uid: post.userId } }"
    >
      <div>Zobacz</div>
    </router-link>
  </div>
  <transition name="details">
    <div v-if="showDetails" class="details">
      <span v-html="post.body"></span>
    </div>
  </transition>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["post"],
  setup() {
    const showDetails = ref(false);

    return { showDetails };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.listItem {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  background-color: $backcolor;
  margin: 5px 0;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: all ease 0.2s;
  overflow: hidden;
}
.listItem span {
  margin: 5px 20px 0 0;
}
.active,
.listItem:hover {
  background-color: $secondcolor;
  color: white;
  font-weight: bold;

  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.listItem span:nth-of-type(2) {
  flex-grow: 1;
  text-align: right;
}
.details {
  text-align: left;
  transition: all 1s ease-out;
  padding: 0 50px;
  border-left: 1px solid $secondcolor;
  border-right: 1px solid $secondcolor;
  overflow: hidden;
}
.btn {
  text-decoration: none;
  background-color: white;
  height: 70%;
  padding: 2px 10px;
  border-radius: 3px;
  font-size: 12px;
  :hover {
    color: red;
  }
}
.details-enter-from,
.details-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.details-enter-active,
.details-leave-active {
  transition: all 2s ease;
}
</style>