<template>
  <div class="list">
    <div v-for="post in posts" :key="post.id">
      <acordeon-list-item :post="post" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  components: {
    "acordeon-list-item": require("@/components/ListAcordeon/AcordeonListItem.vue")
      .default,
  },
  setup() {
    const store = useStore();
    const posts = computed(() => store.state.posts);

    return { posts };
  },
};
</script>

<style scoped lang="scss">
.list {
  display: flex;
  flex-direction: column;
  width: 70%;
}
</style>