import { computed } from "vue";
import getUser from "@/composables/getUser";

const useOwnership = (uid) => {
    const { user } = getUser();
    const ownership = computed(() => {
        return user.value && user.value.uid == uid;
    })

    return { ownership }
}
export default useOwnership