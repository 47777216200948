<template>
  <div class="wraper">
    <acordeon-list />
    <tags-cloud />
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  components: {
    "tags-cloud": require("@/components/TagCloud.vue").default,
    "acordeon-list": require("@/components/ListAcordeon/AcordeonList.vue")
      .default,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch("getPosts");
    });

    return {};
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$font: "Exo", sans-serif;

.wraper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 100px;
  max-width: 1200px;
  gap: 20px;
}
</style>
