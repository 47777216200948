<template>
  <div id="nav">
    <!-- <router-link :to="{ name: 'Posts' }" class="logo"> -->
    <img src="./assets/logoZlosiem.png" alt="logo" @click="goHome" />
    <!-- </router-link> -->
    <div v-if="user" class="links">
      <router-link :to="{ name: 'Chat' }">czat</router-link> |
      <router-link :to="{ name: 'List' }">list</router-link>
      <router-link
        :to="{ name: 'PostsByUser', params: { user: user.displayName } }"
        >currentuser-posts</router-link
      >
      <router-link :to="{ name: 'Posts' }">posts</router-link>
      <router-link :to="{ name: 'AddPost' }">add</router-link>
    </div>
    <div v-if="user" class="logout" @click="handleLogOut">
      {{ `wyloguj: ${user.displayName}` }}
    </div>
  </div>
  <router-view :key="$route.fullPath" v-slot="{ Component }">
    <transition appear name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "App",
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const router = useRouter();
    const store = useStore();

    onMounted(() => {
      store.dispatch("getPosts");
    });
    const goHome = () => {
      router.push({ name: "List" });
    };
    const handleLogOut = async () => {
      await logout();
      router.push({ name: "LogIn" });
    };

    return { user, handleLogOut, goHome };
  },
};
</script>

<style lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;

* {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 0 10px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 80px;
  border-bottom: 1px solid $maincolor;
  z-index: 1000;
  img {
    height: 80%;
  }

  a {
    font-weight: bold;
    color: #2c3e50;
    color: $maincolor;
    text-decoration: none;
    margin: 15px;
    flex-basis: 20%;
    height: 20%;

    &.router-link-exact-active {
      color: white;
      background-color: $secondcolor;
      padding: 5px 20px;
      border-radius: 5px;
    }
  }
}
.logout {
  cursor: pointer;
  background-color: lightslategray;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 0 15px;
}
.logout:hover {
  background-color: $maincolor;
}
a.logo {
  background-color: $maincolor;
  // flex-basis: 10%;
  height: 100px;
  a {
    &.router-link-exact-active {
      background-color: transparent;
    }
  }
  img {
    height: 80%;
  }
}
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateX(1200px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease-in;
}
</style>
