<template>
  <button v-if="!disabled" class="btn" :style="{ backgroundColor: color }">
    {{ text }}
  </button>
  <button v-else class="disabled" disabled>{{ textDisabled }}</button>
</template>

<script>
export default {
  props: ["type", "disabled", "text", "textDisabled", "color"],
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;

.btn {
  padding: 0 30px;
  height: 35px;
  background-color: $secondcolor;
  border: none;
  color: white;
  font-size: 1.1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}
.btn:hover {
  background-color: $maincolor;
}
.disabled,
.disabled:hover {
  @extend .btn;
  background-color: #eee;
}
</style>
