<template>
  <div class="wraper">
    <div v-if="posts.length" class="main">
      <list-by-tag f="" :tag="route.params.tag" :posts="posts" />
    </div>
    <div v-else>Loading data ...</div>
    <tags-cloud />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { onMounted, computed } from "@vue/runtime-core";

export default {
  components: {
    "list-by-tag": require("@/components/List/List.vue").default,
    "tags-cloud": require("@/components/TagCloud.vue").default,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const posts = computed(() => store.state.posts);

    onMounted(() => {
      console.log("getPosts POSTS-BY-TAG -> onMounted");
      store.dispatch("getPosts");
    });

    return { route, posts };
  },
};
</script>

<style scoped lang="scss">
$maincolor: #005ea6;
$secondcolor: #00adeb;
$backcolor: #f7f7f7;
$listItemHeight: 120px;

.wraper {
  padding: 0 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.main {
  grid-column: 1;
}
.red {
  color: red;
}
</style>
