<template>
  <div class="backdrop">
    <div class="modal">
      <div>
        <h4>{{ text1 }}</h4>
        <h4>{{ text2 }}</h4>
        <h5>{{ postId }}</h5>
      </div>
      <div class="buttons">
        <my-button
          type=""
          text="tak"
          @click="$emit('deletePost', postId)"
          color="red"
        />
        <my-button type="" text="nie" @click="$emit('close')" color="green" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "my-button": require("@/components/Button.vue").default,
  },
  props: ["text1", "text2", "postId"],
};
</script>

<style>
.backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 300px;
  background-color: white;
  border-radius: 15px;
  padding: 40px 0;
  /* box-shadow: 0 0 2em #222; */
  /* box-shadow: 0 1.5em 0.5em -1em olive; */
  box-shadow: 0 0 20px black, 20px 15px 30px yellow, -20px 15px 30px lime,
    -20px -15px 30px blue, 20px -15px 30px red;
}
.buttons {
  display: flex;
  justify-content: space-around;
}
</style>
