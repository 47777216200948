import { ref } from "vue";
import { projectFirestore } from "@/firebase/config";

const getCollection = (collection, query) => {

    const documents = ref([]);
    const error = ref(null);

    const loadData = async () => {
        try {
            let collectionRef = projectFirestore.collection(collection)
                .orderBy("created", "desc")
            if (query) {
                collectionRef = collectionRef.where(...query);
            }
            const response = await collectionRef.get();
            documents.value = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            error.value = null
        } catch (err) {
            error.value = err.message;
            console.log(error.value);
        }
    };
    return { documents, error, loadData };
};

export default getCollection;